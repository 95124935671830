<template>
    <span>
        <span v-if="logoId === undefined || logoId === 'undefined' || logoId === null || logoId === ''">
            <!-- Show fixed size home icon if no logo -->
            <span v-if="logoIsVerified === true || logoIsVerified === 'true'">
                <fa icon="circle-check" class="text-success" style="z-index: 500; position: absolute; font-size: 15px; margin-top: 16px; margin-left: 24px" />
            </span>
            <!-- <span class="text-info small">=={{logoWidth}}</span> -->
            <span v-if="logoWidth === undefined || logoWidth === 'undefined' || logoWidth === null || logoWidth === ''">
                <fa icon="house" style="'width: 30px; height: 30px" class="navbar-display-name"/>
            </span>
            <span v-else>
                <fa icon="house" :style="'width: '+ logoWidth +'px; height: '+ logoHeight +'px'" class="navbar-display-name"/>
            </span>
        </span>
        <span v-else>
            <span v-if="logoIsVerified === true || logoIsVerified === 'true'">
                <fa icon="circle-check" class="text-success" style="z-index: 500; position: absolute; font-size: 28px;" :style="'margin-top: ' + iconHeight +  'px; margin-left:' + iconWidth + 'px'" />
            </span>
            <img :src="logoSrc" :width="logoWidth" :height="logoHeight" style="object-fit: cover" alt="logo" />
        </span>
    </span>
</template>

<script>
import {ref, inject, onMounted} from 'vue'
import Popper from 'vue3-popper'
import { useRoute } from 'vue-router'
import funcs from '@/functions/function'

export default {
    name: 'ImgLogo',
    components: { Popper },
    props: ['id', 'isVerified', 'url', 'width', 'height' ],
    setup (props) {
        const axios = inject('axios')
        const route = useRoute()
        const func = funcs

        const logoId = ref(props.id)
        const logoSrc = ref(null) 

        const logoIsVerified = ref(props.isVerified)
        const logoUrl = ref(props.url)
        const logoWidth = ref(props.width)
        const logoHeight = ref(props.height)
        const iconWidth = ref(props.width - 10)
        const iconHeight = ref(props.height - 25)

        onMounted(async () => {

            // console.info('- - - ImgLogo', logoId.value)
            axios.get( '/entity/logo/' + logoId.value, { responseType: 'arraybuffer' }
                ).then((res) => {
                    const b64 = btoa(String.fromCharCode(...new Uint8Array(res.data)))
                    const imgData = 'data:' + res.headers['content-type'] + ';base64,' + b64
                    logoSrc.value = imgData

                    // Get image size from base64
                    // var img = new Image()
                    // img.src = imgData
                    // console.info('new img size', img.width, img.height)
                })
                .catch((error) => {
                    if (error.code === 'undefined' || error.code === undefined) {
                    } else {
                        func.addLog('comp: imglogo', 'onMounted - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error + ' logoId: ' +logoId.value)
                    }
                })
        })

        return { 
            route, logoId, logoSrc, logoIsVerified, logoUrl, logoWidth, logoHeight, iconWidth, iconHeight 
        }
    }
}
</script>

<style>

</style>