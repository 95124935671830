<template>

    <span v-if="showDesc === true && status === 'WIP'">
        <Popper class="popperDark" arrow hover content="Editor are working on the folder and documents">
            <span class="badge bg-success text-white isTooltipsLabel" :class="cls">{{status}}</span>
        </Popper>
    </span>
    <span v-else-if="showDesc === true && status === 'CIRCULATING'">
        <Popper class="popperDark" arrow hover content="Folder is being circulated to signees">
            <span class="badge bg-pink text-white isTooltipsLabel" :class="cls">{{status}}</span>
        </Popper>
    </span>
    <span v-else-if="showDesc === true && status === 'SEALED'">
        <Popper class="popperDark" arrow hover content="All documents are signed and verified. We have sealed your documents in the folder and kept them in a secure shelf">
            <span class="badge bg-purple text-white isTooltipsLabel" :class="cls">{{status}}</span>
        </Popper>
    </span>
    <!-- <span v-if="showDesc === true && status === 'DELETE'">
        <Popper class="popperDark" arrow hover content="Folder is cancelled and will be deleted">
            <span class="badge bg-orange text-white isTooltipsLabel" :class="class">{{status}}</span>
        </Popper>
    </span> -->

    <span v-if="showDesc !== true && status === 'WIP'" class="badge bg-success isLabel text-white" :class="cls">{{status}}</span>
    <span v-else-if="showDesc !== true && status === 'CIRCULATING'" class="badge bg-pink isLabel text-white" :class="cls">{{status}}</span>
    <span v-else-if="showDesc !== true && status === 'SEALED'" class="badge bg-purple isLabel text-white" :class="cls">{{status}}</span>
    <span v-else-if="showDesc !== true && status === 'DELETE'" class="badge bg-orange isLabel text-white" :class="cls">{{status}}</span>

</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'ProjStatus',
    components: { Popper },
    props: [ 'status', 'class', 'showDesc', 'shred' ],
    setup (props) {

        const cls = ref(props.class)
        onMounted(() => {
            // console.info('Tag::onMounted', JSON.stringify(tags.value))
        })

        return { cls }
    }
}
</script>

<style>

</style>